<template>
  <page-view class="com-index" :class="{ 'sort-com': isProvide }" :up-class="'com-index'">
    <meta name="referrer" content="no-referrer">
    <div :style="youno ? { display: 'none' } : { display: 'block' }">
      <div class="com-title">
        <div class="com-title-content">
          <div class="left">
            <img :src="comInfo.logo" alt="" />
            <!-- <img src="@/assets/img/com-logo.jpg" width="100%" alt="" /> -->
            <div class="name">{{ comInfo.name }}</div>
            <!-- <div class="name">圳在线文化传媒(深圳)有限公司</div> -->
            <!-- <div class="btn">关注</div> -->
          </div>
          <div class="right">
            <el-tabs v-model="activeName" @tab-click="changeType">
              <el-tab-pane label="企业介绍" name="comRef" v-if="comInfo.cmsArticle4?.length > 0"></el-tab-pane>
              <el-tab-pane label="公司地址" name="addressRef" v-if="comInfo.addressDetail"></el-tab-pane>

              <el-tab-pane label="精致材料" name="articlesRef" v-if="showArtice"></el-tab-pane>
              <el-tab-pane label="优质产品" name="productRef" v-if="productList.length > 0"></el-tab-pane>
              <el-tab-pane label="优秀案例" name="proCaseRef" v-if="projectList.length > 0"></el-tab-pane>
              <el-tab-pane label="资质证件" name="cerRef" v-if="certificatesList.length > 0"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div class="banner-img" :class="{ bg: comInfo.poster && comInfo.poster.itemList.length < 1 }" ref="comRef">
        <el-carousel style="height: 100%" height="100%" trigger="click" :interval="2000" :arrow="comInfo.poster && comInfo.poster.itemList.length > 1
          ? 'always'
          : 'never'
          ">
          <el-carousel-item v-for="item in comInfo.poster ? comInfo.poster.itemList : []" :key="item.id">
            <img :src="item.image" width="100%" height="100%" alt="" style="cursor: pointer" />
          </el-carousel-item>
        </el-carousel>
        <!-- <div class="name">{{ comInfo.name }}</div> -->
      </div>
      <div class="com-summary" v-if="comInfo.cmsArticle4?.length > 0">
        <div class="summary">
          <div class="summary-title">企业介绍</div>
          <div class="summary-content">
            <div v-html="business.content" @click="toDetail(business, comInfo.cmsArticle4)"></div>
            <div v-if="business.content" class="content-more" @click="toDetail(business, comInfo.cmsArticle4)">
              >>详情
            </div>
          </div>
        </div>
        <div class="right">
          <div v-if="summaryList.length < 1" class="summary-imgs-none">
            <el-empty description="暂未上传" :image="require('../assets/img/com_bg.png')"></el-empty>
          </div>
          <el-carousel v-else height="100%" :interval="2000" trigger="click" @change="changeSummary"
            :arrow="summaryList.length > 1 ? 'always' : 'never'"
            :indicator-position="summaryList.length > 1 ? '' : 'none'">
            <el-carousel-item v-for="item in summaryList" :key="item.id">
              <img :src="item.image" width="100%" height="100%" alt="" style="cursor: pointer"
                @click="toDetail(item, summaryList)" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="com-address" ref="addressRef" v-if="comInfo.addressDetail">
        <div class="title-box">
          <div class="container-tltle">公司地址</div>
          <div class="title-e">COMPANY ADDRESS</div>
        </div>
        <div class="address-content">
          <div class="left">
            <!-- <baidu-map class="bm-view" ak="B0pAWGnggDN7jvDD2MkdCvGsRQ65Yztm" :center="center" :scroll-wheel-zoom="false"
              :zoom="zoom" @ready="handler">
              <bm-marker
                :position="'广东省深圳市福田区园岭街道华林社区八卦路39-2号八卦岭工业区511栋508'"
                :dragging="true"
                animation="BMAP_ANIMATION_BOUNCE"
              >
              </bm-marker> -->
            <!-- <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                <bm-label content="我爱北京天安门" :labelStyle="{ color: 'red', fontSize: '24px' }"
                  :offset="{ width: -35, height: 30 }" />
              </bm-marker> -->
            <!-- </baidu-map> -->
            <baidu-map class="bm-view" ak="B0pAWGnggDN7jvDD2MkdCvGsRQ65Yztm" :center="center" :scroll-wheel-zoom="false"
              :zoom="zoom" :auto-resize="true" @ready="handler">
              <bm-marker :position="markerPoint" :dragging="false" @click="jump">
                <bm-info-window :show="show" :width="220" :height="0" @close="infoWindowClose" @open="infoWindowOpen">
                  <div class="adder">
                    <div>{{ comInfo.name }}</div>
                    <p v-text="comInfo.addressDetail"></p>
                    <button @click="clear">去这里</button>
                  </div>
                </bm-info-window>
              </bm-marker>
            </baidu-map>
          </div>
          <div class="right">
            <div class="right-title">{{ comInfo.name }}</div>
            <!-- <div class="right-title">圳在线文化传媒(深圳)有限公司</div> -->
            <ul>
              <li>公司地址</li>
              <li>{{ comInfo.addressDetail }}</li>
            </ul>
            <ul>
              <li>公司类型</li>
              <li>
                {{ companyType }}
              </li>
            </ul>

            <button class="adderss" @click="clear">去这里</button>
          </div>
        </div>
      </div>
      <div class="articles-make" ref="articlesRef" v-if="showArtice">
        <div class="title-box">
          <div class="container-tltle">精致材料</div>
          <div class="title-e">EXQUISITE MATERIALS</div>
        </div>
        <div class="look-more">
          <div class="btn" @click="lookMore">
            查看更多<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="articles-content">
          <div class="tabs">
            <div class="tabs-item" v-for="item in articles" :key="item.id" :class="{ active: articlesIndex == item.id }"
              @click="changeMater(item)">
              {{ comInfo.easy_name }}-{{ item.name }}
            </div>
          </div>
          <div class="tabs-content">
            <div class="tabs-content-item" v-for="item in maters" :key="item.id" @click="toArticlesDetail(item.id)">
              <img v-lazy="item.image" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="product" ref="productRef" v-if="!showArtice && productList?.length > 0">
        <div class="title-box">
          <div class="container-tltle">优质产品</div>
          <div class="title-e">QUALITY PTODUCT</div>
        </div>
        <div class="look-more">
          <div class="btn" @click="toPropductMore">
            查看更多<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="product-content">
          <div class="product-item" v-for="item in productList" :key="item.id" @click="toDetail(item, productList)">
            <img class="item-img" :src="item.image" alt="" />
            <div class="item-name">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="project-case" ref="proCaseRef" v-if="projectList?.length > 0">
        <div class="title-box">
          <div class="container-tltle">优秀案例</div>
          <div class="title-e">PROJECT CASE</div>
        </div>
        <div class="look-more">
          <div class="btn" @click="toCaseMore">
            查看更多<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="case-content">
          <div class="case-item" v-for="item in projectList" :key="item.id" @click="toDetail(item, projectList)">
            <img class="item-img" :src="item.image" alt="" />
            <div class="case-info">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-describe">
                <span>项目描述：</span>{{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="certificates" ref="cerRef" v-if="certificatesList.length > 0">
        <div class="title-box">
          <div class="container-tltle">资质证件/荣誉证书</div>
          <div class="title-e">QUALIFICATION CERTIFICATE</div>
        </div>
        <div v-if="isMobile" class="certificates-content-m">
          <ul class="img-items">
            <li v-for="(itemVal, indexVal) in certificates" :key="indexVal" class="img-item">
              <img :src="itemVal.image" width="100%" alt="" />
              <div class="txt">
                <p>{{ itemVal.title }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="certificates-content">
          <el-carousel :interval="5000" arrow="always" indicator-position="none">
            <el-carousel-item v-for="(item, index) in certificatesList" :key="index">
              <ul class="img-items">
                <li v-for="(itemVal, indexVal) in item" :key="indexVal" class="img-item">
                  <img :src="itemVal.image" width="100%" height="265px" alt="" />
                  <div class="txt">
                    <p>{{ itemVal.title }}</p>
                    <!-- <p>XXXXXXXXX深圳XX单位颁发</p> -->
                  </div>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="com-titless" :style="youno && !isMobile ? { display: 'block' } : { display: 'none' }">
      <div class="com-title-content">
        <div class="left">
          <img :src="comInfo.logo" alt="" />
          <div class="name">{{ comInfo.name }}</div>
        </div>
        <div class="right">
          <el-tabs v-model="activeName">
            <el-tab-pane label="企业介绍" name="comRef" v-if="comInfo.cmsArticle4?.length > 0"></el-tab-pane>
            <el-tab-pane label="公司地址" name="addressRef" v-if="comInfo.addressDetail"></el-tab-pane>

            <el-tab-pane label="精致材料" name="articlesRef" v-if="showArtice"></el-tab-pane>
            <el-tab-pane label="优质产品" name="productRef" v-if="productList.length > 0"></el-tab-pane>
            <el-tab-pane label="优秀案例" name="proCaseRef" v-if="projectList.length > 0"></el-tab-pane>
            <el-tab-pane label="资质证件" name="cerRef" v-if="certificatesList.length > 0"></el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="youno" :style="youno ? { display: 'block' } : { display: 'none' }"></div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import BmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue";
import BmLabel from "vue-baidu-map/components/overlays/Label.vue";

import axios from "axios";
// import
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BaiduMap,
    BmMarker,
    BmInfoWindow,
    BmLabel,
  },
  name: "ComIndex",
  data() {
    return {
      map: "",
      center: { lng: 0, lat: 0 },
      markerPoint: { lng: 0, lat: 0 },
      show: true,
      name: "",
      zoom: 3,
      activeName: "comRef",
      bannerImgs: [
        // { id: 0, image: require("../assets/img/comindex_1.png") },
      ],
      refData: [
        { name: "comRef", label: "企业介绍" },
        { name: "addressRef", label: "公司地址" },
        { name: "articlesRef", label: "精致材料" },
        { name: "productRef", label: "优质产品" },
        { name: "proCaseRef", label: "优秀案例" },
        { name: "cerRef", label: "资质证件" },
      ],
      articlesIndex: 0,
      articles: [], //精致材料tabs
      maters: [], //材料列表
      comInfo: {},
      business: {},
      companyTypeList: [
        { label: "品牌方", id: "1" },
        { label: "设计公司", id: "2" },
        { label: "深化公司", id: "3" },
        { label: "道具公司", id: "4" },
        { label: "装修公司", id: "5" },
        { label: "材料公司", id: "6" },
        { label: "监理单位", id: "7" },
        { label: "物流公司", id: "8" },
      ],
      certificates: [],
      projectList: [],
      summaryImgs: [],
      showArtice: true, //是否显示材料
      productList: [], //优质产品
      youno: false
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
    summaryList() {
      let comInfo = this.comInfo;
      if (comInfo.cmsArticle1 && comInfo.cmsArticle1.length > 0) {
        return comInfo.cmsArticle1;
      } else if (comInfo.cmsArticle2 != null && comInfo.cmsArticle2.length > 0) {
        return comInfo.cmsArticle2;
      } else {
        return [];
      }
    },
    companyType() {
      return this.companyTypeList.find(
        (item) => item.id === this.comInfo.bc_company_type
      )?.label;
    },
    isProvide() {
      let flag = this.$route.query.isProvide || sessionStorage.isProvide;
      sessionStorage.removeItem("isProvide");
      return flag;
    },
    certificatesList() {
      let newArr = [];
      let index = 0;
      while (index < this.certificates.length) {
        newArr.push(this.certificates.slice(index, (index += 4)));
      }
      return newArr;
    },
  },
  created() {
    this.getComInfo();
    this.getComListId();
    this.getArticles();
    this.getMater().then((res) => {
      this.showArtice = res.size > 0 ? true : false;
    });
  },
  mounted() {
    if (sessionStorage.tabIndex) {
      this.$refs[sessionStorage.tabIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      this.activeName = sessionStorage.tabIndex;
      sessionStorage.removeItem("tabIndex");
    }
  },
  methods: {
    ...mapActions([
      "setTheArticle",
      "setTheArticleList",
      "setComInfo",
      "setCaseMore",
    ]),
    async getComInfo() {
      let result = await this.$api.getComInfo({
        syscompanyid: this.$route.params.syscompanyid,
      });

      if (result.data && result.code == 200) {
        let nums = 0;//跳转判断是否两数组以上为空，为空不跳转
        for (let i = 1; i < 5; i++) {
          let num = 'cmsArticle' + i
          let a = result.data[num]
          if (a.length > 0) {
            nums++
          }
        }

        if (nums < 1) {
          this.youno = true
          // return
        } else {
          this.youno = false
        }
        this.comInfo = result.data;

        this.setComInfo(result.data); //放全局
        //修改网页标题
        document.title = `${this.companyType}-${this.comInfo.name}`;
        this.$route.meta.title = this.comInfo.name;
        this.$nextTick(() => {
          this.center = this.comInfo.addressDetail;
          this.name = this.comInfo.name;
          axios.jsonp = (url) => {
            if (!url) {
              console.error("Axios.JSONP 至少需要一个url参数!");
              return;
            }
            return new Promise((resolve, reject) => {
              window.jsonCallBack = (result) => {
                resolve(result);
              };
              var JSONP = document.createElement("script");
              JSONP.type = "text/javascript";
              JSONP.src = `${url}&callback=jsonCallBack`;
              document.getElementsByTagName("head")[0].appendChild(JSONP);
              setTimeout(() => {
                document.getElementsByTagName("head")[0].removeChild(JSONP);
              }, 500);
            });
          };
          axios
            .jsonp(
              `https://api.map.baidu.com/geocoding/v3/?address=${this.comInfo.addressDetail}&output=json&ak=B0pAWGnggDN7jvDD2MkdCvGsRQ65Yztm&callback=showLocation`
            )
            .then((res) => {
              this.markerPoint = res.result.location;
            });

          this.handler();
        });
        //判断下接口不正常数据
        this.business = Array.isArray(result.data.cmsArticle4) && result.data.cmsArticle4.length > 0 ? result.data.cmsArticle4[0] : {};
        // 资质证件
        this.certificates = this.comInfo.payImages[0] ? this.comInfo.payImages[0] : [];
      }else{
        this.$message.error(result.message)
      }
    },
    async getComListId() {
      //获取文章类别编号
      let result = await this.$api.getComListId({
        conpanyid: this.$route.params.syscompanyid,
      });
      // 获取公司介绍轮播图
      let resultSummary = await this.$api.getComList({
        categoryId: result.data.find((item) => item.bcce === 2)?.id,
        index: 0,
        size: 4,
        type: 16,
      });
      this.summaryImgs = resultSummary.data?.list;
      //获取项目案例
      let resultProject = await this.$api.getComList({
        categoryId: result.data.find((item) => item.bcce === 2)?.id,
        index: 0,
        size: 4,
        type: 16,
      });
      this.projectList = resultProject.data?.list;
      //获取优质产品
      let productRes = await this.$api.getComList({
        categoryId: result.data.find((item) => item.bcce === 1)?.id,
        index: 0,
        size: 6,
        type: 16,
      });
      let arr = productRes.data?.list.slice(0, 3)
      this.productList = arr
    },
    //查看更多文章
    toCaseMore() {
      this.setCaseMore({
        syscompanyid: this.$route.params.syscompanyid,
        bcce: 2, //案例
      });
      this.$router.push({
        name: "CaseMore",
      });
    },
    toPropductMore() {
      this.setCaseMore({
        syscompanyid: this.$route.params.syscompanyid,
        bcce: 1, //产品
      });
      this.$router.push({
        name: "CaseMore",
      });
    },
    changeSummary(i) {
      this.business = this.comInfo.cmsArticle4[i];
    },
    toDetail(item, _arr) {
      this.setTheArticle(item);
      // 修改文章列表
      this.setTheArticleList(_arr);
      const openRoute = this.$router.resolve({
        name: "ExcellentDesign",
      });
      window.open(openRoute.href, "_blank");
    },
    handler() {
      this.zoom = 15;
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
    jump() {
      this.clear();
    },
    clear() {
      window.location.href = `http://api.map.baidu.com/marker?location=${this.markerPoint.lat},${this.markerPoint.lng}&title=${this.name}&content=${this.center}&output=html&src=webapp.baidu.openAPIdemo `;
    },
    changeType(tab) {
      this.$refs[tab.name].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    },
    lookMore() {
      let isRefData = this.refData.filter((item) => this.$refs[item.name]);
      this.$router.push({
        name: "FineMaterial",
        params: { isRefData },
      });
    },
    // 获取材料分类
    async getArticles() {
      let result = await this.$api.getMaterClass(
        this.$route.params.syscompanyid
      );
      let resArr = result.data.slice(0, 2);
      resArr.push({
        name: "其他",
        id: null,
      });
      this.articles = resArr;
      this.articlesIndex = resArr[0].id;
      this.getMater(resArr[0].id);
    },
    changeMater(item) {
      this.articlesIndex = item.id;
      this.getMater(item.id);
    },
    async getMater(id) {
      let { code, data } = await this.$api.getMaterList({
        companyid: this.$route.params.syscompanyid,
        categoryId: id,
        index: 0,
        size: 3,
      });
      this.maters = data.list;
      return data;
    },
    //材料详情
    toArticlesDetail(id) {
      const openRoute = this.$router.resolve({
        name: "ArticlesDetail",
        params: {
          goodsId: id,
        },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>
<style lang='scss' scoped>
.adder {
  div {
    font-size: 14px;
  }

  p {
    font-size: 12px;
    margin: 6px 0;
  }

  button {
    width: 50px;
    height: 25px;
    border: 1px solid #439c4c;
    color: #439c4c;
    background-color: #fff;
    border-radius: 10px;
    font-size: 12px;
    line-height: 23px;
  }
}

.adderss {
  width: 100%;
  height: 40px;
  margin: 50px auto 0;
  // margin-left: calc(50% - 50px);
  border: 1px solid #439c4c;
  color: #439c4c;
  background-color: #fff;
  border-radius: 30px;
}

.com-index {
  background-color: #f5f5f5;
  @include flexbox(column);

  .youno {
    height: 100vh;
    width: 100%;
    position: relative;
    font-size: 1vw;

    &:empty::after {
      content: '内容正在完善，敬请期待...';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // font-family: 'title';
      font-size: 1.2vw;
    }
  }

  .com-title {
    background-color: #f6faff;
    padding: 20px 0;
    position: sticky;
    top: 0px;
    z-index: 99;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    .com-title-content {
      width: $containerWidth;
      margin: 0px auto;
      @include flexbox(row, space-between, center);

      .left {
        @include flexbox(row, flex-start, center);

        >img {
          width: 120px;
          height: 70px;
          object-fit: contain;
        }

        .name {
          font-size: 24px;
          margin: 0 20px;
        }

        .btn {
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
          border-radius: 50px;
          padding: 6px 25px;
          font-size: 12px;
        }
      }

      .right {
        :deep(.el-tabs) {
          .el-tabs__nav-wrap::after {
            content: none;
          }

          .el-tabs__header {
            margin: 0;
          }

          .el-tabs__item:hover {
            color: $primaryColor;
          }

          .el-tabs__item.is-active {
            color: $primaryColor;
          }

          .el-tabs__active-bar {
            background-color: $primaryColor;
          }
        }
      }
    }
  }

  .com-titless {
    background-color: #f6faff;
    padding: 20px 0;
    position: sticky;
    top: 0px;
    z-index: 99;

    .com-title-content {
      width: 80%;
      margin: 0px auto;
      @include flexbox(row, space-between, center);

      .left {
        @include flexbox(row, flex-start, center);

        >img {
          width: 120px;
          height: 70px;
          object-fit: contain;
        }

        .name {
          font-size: 24px;
          margin: 0 20px;
        }

        .btn {
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
          border-radius: 50px;
          padding: 6px 25px;
          font-size: 12px;
        }
      }

      .right {
        :deep(.el-tabs) {
          .el-tabs__nav-wrap::after {
            content: none;
          }

          .el-tabs__header {
            margin: 0;
          }

          .el-tabs__item:hover {
            color: $primaryColor;
          }

          .el-tabs__item.is-active {
            color: $primaryColor;
          }

          .el-tabs__active-bar {
            background-color: $primaryColor;
          }
        }
      }
    }
  }

  .banner-img {
    height: 21vw;
    margin-bottom: 30px;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &.bg {
      background-image: url("../assets/img/comindex_1.png");
    }

    >img {
      width: 100%;
      height: 100%;
    }

    .name {
      position: absolute;
      color: #fff;
      font-size: 40px;
      top: 50%;
      transform: translateY(-50%);
      left: 18%;
      z-index: 2;
      text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.68);

      @media screen and (max-width: 1200px) {
        font-size: 26px;
        left: 4%;
      }
    }

    :deep(.el-carousel) {
      .el-carousel__arrow {
        font-size: 28px;
        @include flexbox(row, center, center);
        height: 40px;
        width: 40px;
        background-color: rgba(31, 45, 61, 0.4);

        &:hover {
          background-color: rgba(31, 45, 61, 0.2);
        }
      }
    }
  }

  .com-summary {
    height: 350px;
    width: $containerWidth;
    margin: 0px auto;
    margin-bottom: 80px;
    @include flexbox(row);

    @media screen and (max-width: 1200px) {
      width: 100%;
      height: auto;
      @include flexbox(column);
      padding: 0 0.2rem;
      box-sizing: border-box;
    }

    .summary {
      background-color: #fff;
      width: 478px;
      min-height: 350px;
      margin-right: 20px;
      padding: 30px;
      box-sizing: border-box;
      @include flexbox(column);

      @media screen and (max-width: 1200px) {
        // order: 2;
        min-height: 240px;
        width: 100%;
      }

      .summary-title {
        font-size: 24px;
        color: #4c4c4c;
        margin-bottom: 20px;
      }

      .summary-content {
        // flex: 1;
        color: #595959;
        font-size: 12px;
        line-height: 1.8;
        display: -webkit-box;
        overflow: hidden;
        /*超出隐藏*/
        text-overflow: ellipsis;
        /*隐藏后添加省略号*/
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 11; //想显示多少行
        position: relative;

        .content-more {
          color: #999999;
          line-height: 1.8;
          font-size: 12px;
          padding-left: 24px;
          position: absolute;
          bottom: 1px;
          right: 0;
          cursor: pointer;

          &:hover {
            color: $primaryColor;
          }
        }

        >div {
          cursor: pointer;
        }

        // :deep(img) {
        //   display: none;
        // }
      }
    }

    .right {
      flex: 1;
      overflow: auto;
      background-color: #e8e8e8;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      @media screen and (max-width: 1200px) {
        flex: auto;
        height: 240px;
        order: 1;
      }

      .summary-imgs-none {
        height: 100%;
        @include flexbox(row, center, center);

        :deep(.el-empty) {
          padding: 0;

          .el-empty__image {
            width: 280px;

            @media screen and (max-width: 1200px) {
              width: 3.2rem;
            }
          }
        }
      }

      :deep(.el-carousel) {
        height: 100%;

        .el-carousel__arrow {
          font-size: 28px;
          @include flexbox(row, center, center);
          height: 40px;
          width: 40px;
          background-color: rgba(31, 45, 61, 0.4);

          &:hover {
            background-color: rgba(31, 45, 61, 0.2);
          }
        }
      }
    }
  }

  .title-box {
    position: relative;

    .container-tltle {
      z-index: 1;
    }

    .title-e {
      position: absolute;
      color: #e4f0e5;
      letter-spacing: 2px;
      font-size: 45px;
      font-weight: bold;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 1200px) {
        font-size: 24px;
        white-space: pre;
        transform: translateX(-50%) translateY(50%);
      }
    }
  }

  .com-address {
    width: 62%;
    margin: 80px auto;

    // margin-bottom: 30px;
    // padding: 50px 0;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .address-content {
      @include flexbox(row);
      height: 440px;

      @media screen and (max-width: 1200px) {
        @include flexbox(column);
        height: auto;
        padding: 0 0.2rem;
        box-sizing: border-box;
      }

      .left {
        flex: 1;
        overflow: auto;
        // background-color: #d9d9d9;
        border: 1px rgba(222, 222, 222, 1) solid;
        padding: 2px;
        box-sizing: border-box;

        @media screen and (max-width: 1200px) {
          flex: auto;
          height: 220px;
        }

        .bm-view {
          height: 100%;

          :deep(.anchorBL) {
            display: none;
          }
        }
      }

      .right {
        width: 310px;
        margin-left: 20px;
        background-color: #fff;
        padding: 50px 20px;
        box-sizing: border-box;

        @media screen and (max-width: 1200px) {
          margin-left: 0;
          width: 100%;
        }

        .right-title {
          margin-bottom: 20px;
        }

        >ul {
          margin-top: 20px;

          li {
            font-size: 12px;
            margin-top: 10px;

            &:first-child {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }

  .product {
    width: $containerWidth;
    margin: 0px auto;
    margin-bottom: 60px;
    padding: 50px 0;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .container-tltle {
      margin-bottom: 0 !important;
    }

    .look-more {
      @include flexbox(row, flex-end);

      .btn {
        font-size: 12px;
        background: #ffffff;
        box-shadow: 0px 20px 20px 0px rgba(67, 156, 76, 0.1);
        border-radius: 50px 50px 50px 50px;
        padding: 10px 10px 10px 15px;
        cursor: pointer;
      }
    }

    .product-content {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      background: #ffffff;
      padding: 35px 20px;
      box-sizing: border-box;

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.3rem;
        padding: 0.3rem 0.2rem;
      }

      .product-item {
        cursor: pointer;

        .item-img {
          width: 100%;
          height: 254px;

          @media screen and (max-width: 768px) {
            height: 2.35rem;
          }
        }

        .item-name {
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }

  .project-case {
    width: $containerWidth;
    margin: 0px auto;
    margin-bottom: 60px;
    padding: 50px 0;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .container-tltle {
      margin-bottom: 0 !important;
    }

    .look-more {
      @include flexbox(row, flex-end);

      .btn {
        font-size: 12px;
        background: #ffffff;
        box-shadow: 0px 20px 20px 0px rgba(67, 156, 76, 0.1);
        border-radius: 50px 50px 50px 50px;
        padding: 10px 10px 10px 15px;
        cursor: pointer;
      }
    }

    .case-content {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;

      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
        padding-right: 5%;
        box-sizing: border-box;
      }

      .case-item {
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        min-height: 200px;

        .item-img {
          position: absolute;
          right: -5%;
          bottom: -10%;
          width: 200px;
          height: 138px;

          @media screen and (max-width: 1200px) {
            width: 2.4rem;
            height: 1.84rem;
          }
        }

        .case-info {
          .item-title {
            font-size: 24px;
            display: -webkit-box;
            overflow: hidden;
            /*超出隐藏*/
            text-overflow: ellipsis;
            /*隐藏后添加省略号*/
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; //想显示多少行
          }

          .item-describe {
            width: 70%;
            color: #606266;
            font-size: 14px;
            margin-bottom: 12px;
            margin-top: 20px;
            word-break: break-all;
          }

          .item-describe1 {
            width: 70%;
            display: -webkit-box;
            overflow: hidden;
            /*超出隐藏*/
            text-overflow: ellipsis;
            /*隐藏后添加省略号*/
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; //想显示多少行

            :deep(img) {
              display: none;
            }
          }
        }
      }
    }
  }

  .articles-make {
    width: $width;
    margin: 0px auto;
    margin-bottom: 80px;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .title-box {
      .title-e {
        white-space: nowrap;
      }
    }

    .look-more {
      @include flexbox(row, flex-end);

      .btn {
        font-size: 12px;
        background: #ffffff;
        box-shadow: 0px 20px 20px 0px rgba(67, 156, 76, 0.1);
        border-radius: 50px 50px 50px 50px;
        padding: 10px 10px 10px 15px;
        cursor: pointer;
      }
    }

    .articles-content {
      margin-top: 15px;

      .tabs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background-color: #4c534c;

        .tabs-item {
          text-align: center;
          padding: 20px 0;
          position: relative;
          color: #fff;
          cursor: pointer;

          &::after {
            content: "";
            height: 0;
            transition: height 0.2s linear;
          }

          &.active {
            &::after {
              content: "";
              height: 6px;
              background-color: $primaryColor;
              width: 20%;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      .tabs-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 30px;
        box-sizing: border-box;

        .tabs-content-item {
          margin: 0 15px;
          cursor: pointer;

          >img {
            width: 100%;
          }

          >p {
            text-align: center;
            margin-top: 15px;
          }
        }
      }
    }
  }

  .certificates {
    margin-bottom: 100px;
    background-color: #43474c;
    padding: 40px 0;

    .title-box {
      .container-tltle {
        color: #ffffff;
        letter-spacing: 6px;
        font-size: 36px;
      }

      .title-e {
        color: #439c4c;
        opacity: 0.16;
      }
    }

    .certificates-content-m {
      .img-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        padding: 0.2rem;

        .img-item {
          img {
            height: 3rem;
          }

          >.txt {
            color: #fff;
            font-size: 16px;
            line-height: 1.8;
            text-align: center;
            margin-top: 0.1rem;

            p:last-child {
              font-size: 12px;
            }
          }
        }
      }
    }

    .certificates-content {
      width: $containerWidth;
      margin: 0px auto;
      padding: 20px 0;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      :deep(.el-carousel__container) {
        height: 320px;

        .el-carousel__arrow {
          font-size: 28px;
          @include flexbox(row, center, center);
          height: 40px;
          width: 40px;
          background-color: rgba(31, 45, 61, 0.71);
        }
      }

      .img-items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0 60px;

        .img-item {
          >.txt {
            color: #fff;
            font-size: 16px;
            line-height: 1.8;
            text-align: center;

            p:last-child {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.sort-com {
  .com-summary {
    order: 1;
  }

  .com-address {
    order: 3;
  }

  .project-case {
    order: 4;
  }

  .articles-make {
    order: 2;
  }

  .certificates {
    order: 5;
  }
}
</style>